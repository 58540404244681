@import '../../variables/variables.scss';

// custom font size classes 
.custom-f-9{
    font-size: $font-9 ;
}
.custom-f-10{
    font-size: $font-10;
}
.custom-f-11{
    font-size: $font-11;
}
.custom-f-12{
    font-size: $font-12;
}
.custom-f-13{
    font-size: $font-13;
}
.custom-f-14{
    font-size: $font-14 !important;
}
.custom-f-15{
    font-size: $font-15;
}
.custom-f-16{
    font-size: $font-16;
}
.custom-f-17{
    font-size: $font-17;
}
.custom-f-18{
    font-size: $font-18;
}
.custom-f-19{
    font-size: $font-19;
}
.custom-f-20{
    font-size: $font-20 !important;
}
.custom-f-21{
    font-size: $font-21;
}
.custom-f-22{
    font-size: $font-22;
}
.custom-f-23{
    font-size: $font-23;
}
.custom-f-24{
    font-size: $font-24;
}
.custom-f-25{
    font-size: $font-25;
}
.custom-f-26{
    font-size: $font-26;
}
.custom-f-27{
    font-size: $font-27;
}
.custom-f-28{
    font-size: $font-28;
}
.custom-f-29{
    font-size: $font-29;
}
.custom-f-30{
    font-size: $font-30;
}

//