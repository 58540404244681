/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/variables/variables.scss";
@import './assets/scss/custom-font-size/custom-font-size';
@import './assets/scss/custom/layout-responsive-root.scss';
@import './assets/scss/custom/custom-common-dropdwon.scss';



.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

// html{
//     font-size: 70%;
// }


.page-body{
    background-color: #E9EBF2;
}

p{
    letter-spacing: 0;
}


//font family new 
@font-face {
    font-family: "Inter-400";
    src: local("Inter-400"),
        url(./assets/font-family/inter/Inter-400.ttf) format("truetype");
}
@font-face {
    font-family: "Inter-500";
    src: local("Inter-500"),
        url(./assets/font-family/inter/Inter-500.ttf) format("truetype");
}
@font-face {
    font-family: "Inter-600";
    src: local("Inter-600"),
        url(./assets/font-family/inter/Inter-600.ttf) format("truetype");
}
@font-face {
    font-family: "Inter-700";
    src: local("Inter-700"),
        url(./assets/font-family/inter/Inter-700.ttf) format("truetype");
}

//utility common classes start 

//font-family start
.font-inter-400{
    font-family: "Inter-400";
}

.font-inter-500{
    font-family: "Inter-500";
}

.font-inter-600{
    font-family: "Inter-600";
}

.font-inter-700{
    font-family: "Inter-700";
}
//font-family end

.text-theme-color-success{
    color:$theme-color-success;
}

.text-theme-color-error{
    color:$theme-color-error;
}
.text-theme-color-accent{
    color: $theme-color-accent;
}

.text-theme-color-sapphire-50{
    color:$theme-color-sapphire-50 !important;
}
.text-theme-color-sapphire-80{
    color:$theme-color-sapphire-80 !important;
}
.text-theme-color-sapphire-30{
    color:$theme-color-sapphire-30 !important;
}
.text-theme-color-sapphire-20{
  color: $theme-color-sapphire-20;
}

.text-theme-color-ivory-80{
    color:$theme-color-ivory-80 !important;
}
.text-theme-color-Ivory-40{
    color: $theme-color-Ivory-40;
}
.text-theme-color-ivory-60{
    color:$theme-color-ivory-60;
}

.text-theme-color-Warning{
    color:$theme-color-Warning;
}

.text-theme-color-grey {
    color:$theme-color-grey;
}

.text-theme-color-dark{
    color:$theme-color-dark !important;
}

.text-color-black-salute{
    color:$theme-color-black-salute;
}
.text-color-accent{
    color: $theme-color-accent ;
}

.text-theme-color-sapphire-95 {
    color: $theme-color-sapphire-95  ;
}

.bg-theme-color-Warning{
    background-color:$theme-color-Warning !important;
}

.bg-theme-color-ivory-80{
    background-color:$theme-color-ivory-80 !important;
}

.bg-theme-color-success{
    background-color:$theme-color-success !important;
}

.bg-theme-color-white{
    background-color: $theme-color-white !important;
}
.bg-theme-color-error{
    background-color: $theme-color-error !important;
}

.bg-theme-color-sapphire-50{
    background-color:$theme-color-sapphire-50 !important;
}
.bg-theme-color-grey {
    background-color:$theme-color-grey;
}
.bg-theme-color-accent{
    background-color: $theme-color-accent ;
}
.bg-theme-color-success-surface{
    background-color: $theme-color-success-surface ;
}
.bg-theme-color-warning-surface{
    background-color: $theme-color-warning-surface ;
}
.bg-theme-color-error-surface{
    background-color: $theme-color-error-surface ;
}
.bg-theme-color-sapphire-80{
    background-color:$theme-color-sapphire-80 !important;
}
.text-theme-color-white{
    color: $theme-color-white !important;
}
.border-sapphire-50{
    color: $theme-color-sapphire-50;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}

.text-theme-color-ivory-90{
    color: $theme-color-ivory-90 !important;
}
.br-20{
    border-radius: 1.25rem;
}

.cursor-pointer{
    cursor: pointer;
}

.h-0{
    height: 0;
}

.letter-space-1{
    letter-spacing: 1px;
}
.letter-space-0{
    letter-spacing: 0;
}

.cursor-disabled{
    cursor: disabled;
}

.m-t-13{
    margin-top: 0.813rem;
}
.m-b-13{
    margin-bottom: 0.813rem;
}

.w-9-5rem{
    min-width: 10.5rem !important;
}
//utility common classes end 

.text-underline-offcet{
 text-decoration: underline;
 text-underline-offset: 0.2rem;
}

//event list page table and ngx datatable for all tables start 
.evntList_page_table , .hosted_events_table,.ticket_purchased_table ,.purchase_made_table ,.reports_page_table ,.hosted_tickets_table ,.payments_table ,.user_management_container{
    .ngx-datatable{
        height: 100%;
        .visible{
            height: 100%;
            display: flex;
            flex-direction: column;
            .datatable-header{
                border-bottom: 1px solid $theme-color-sapphire-50;
                height: 1.875rem !important;
                .datatable-header-cell{
                    padding: 0 0px 0 1.25rem;
                    font-family: "Inter-700";
                    font-size: $font-16;
                    color: $theme-color-dark ;
                    line-height: 1.165;
                    // &:nth-child(1){
                    //     padding-left: 15px;
                    // }
                }
            }
            .datatable-body{
                flex: 1 1 auto !important;
                height: 0 !important;
                .empty-row{
                   display: grid;
                   place-items: center;
                   font-family: "Inter-400";
                   height: 100%;
                   color:  $theme-color-dark;
                }
                .datatable-scroll{
                    display: table !important;
                    width: 100% !important;
                    .datatable-row-wrapper{
                        width: inherit !important;
                        .datatable-body-row{
                            width: 100% !important;
                            border-bottom: 1px solid #E9EBF2;
                            height: 2.5rem !important;
                            cursor: pointer;
                            // &.active{
                            //     background-color: #5663EE33;
                            // }
                                &.custom-selected-row{
                                    background-color: #5663EE33;
                                }
                            .datatable-body-cell{
                                display: flex;
                                align-items: center;
                                padding: 0 0 0 1.25rem;
                                font-family: "Inter-400";
                                color: $theme-color-dark;
                                font-size: $font-14;
                                height: 2.5rem !important;
                                .datatable-body-cell-label{
                                    width: 100%;
                                    div{
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .datatable-footer{
                .ml-custom-resp{
                    margin-left: -4rem;
                }
            }
        }
    }
}

//event list page table ngx datatable for all tables end

//pagination scss start
ngb-pagination{
    .page-link {
        padding: 0.313rem 0.938rem;
        color: $theme-color-sapphire-50 !important;
        border: 1px solid $theme-color-sapphire-50 !important;
        font-size: $font-14;
        font-family: "Inter-400";
        letter-spacing: normal;
      }
  
      .page-item.active .page-link {
        padding: 0.313rem 0.938rem;
        background-color:$theme-color-sapphire-50 !important;
        border-color: $theme-color-sapphire-50 !important;
        color: $theme-color-white !important;
        font-size: $font-14;
        letter-spacing: normal;
      }

      .page-item.disabled{
        opacity: 1 !important;
        a{
            color: #C4C9F1 !important;
            background-color: $theme-color-white;
        }
      }
}

//pagination scss end

::ng-deep input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent!important;
    color: #000 !important;
}

.stable_scroll {
    scrollbar-gutter: stable both-edges;
}

//webkit scroll bar start
*::-webkit-scrollbar {
    width: 0px;
}
*::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

// *::-webkit-scrollbar-thumb {
//     background-color: $theme-color-ivory-60;
//     border-radius: 10px;
//     border: 0.5px solid $theme-color-white;
// }

// *::-webkit-scrollbar-track
// {
//     background-color: #FFF8F1;
//     border-radius: 10px;
//     border: 0.5px solid $theme-color-ivory-10;
// }

// //webkit scroll bar end

//custom css for  popUP start
.modal-content {
    border-radius: 1.2rem;
    background-color: $theme-color-white;
}

.confirm_popUp{
    .modal-dialog{
        min-width: 30.875rem;
    }
}
//custom css for popUP end

//custom widht set rangepicker in reports page start//
.reports_transactions_container ,.reports_events_container{
    .range-datepicker{
        width: 15.5rem !important;
    }
}

//custom widht set rangepicker in reports page end//

//tabs common css height in percernt(100%)
.tab-content{
    .tab-pane{
        height: 100%;
    }
}
//end

//dashboared scss start 

//radial bar start
    #apexcharts-radialbarTrack-0{
        fill: $theme-color-white;
    }
//radial bar end

//donut chart start apexcharts-legend
.donut_chart{
    .apexcharts-datalabels{
        display: none;
    }
}
//donut chart end

// Revenue Chart Starts
// .subscriber-chart-container{
//     apx-chart{
//         .apexcharts-canvas {
//             height: 22.5rem !important;
//             width: 100% !important ;
//             overflow: auto;
//             scrollbar-width: none; 
//             -ms-overflow-style: none; 
//             &::-webkit-scrollbar {
//                 height: 0.9rem !important;
//             }
          
//             svg{
//                 height: 19.75rem;
//                 foreignObject{
//                     height: 19.9rem;
//                 }
//             }
//             // @media screen and (min-width: 1554px){
//             //     svg{
//             //         height: 20.75rem;
//             //         foreignObject{
//             //             height: 20.75rem;
//             //         }
//             //     }
//             // }

//         }
//     }   
// }
// Revenue Chart Ends



//dashboared scss end

// @media screen and (max-width: 985px){
//     .evntList_page_table {
//         .ngx-datatable{
//             .datatable-body{
//                 height: 11.5rem !important;
//             }
//             .datatable-footer{
//                 border-bottom:1px solid $theme-color-sapphire-50 !important;
//             }          
//         }
//     }
//     .purchase_made_table, .hosted_tickets_table ,.payments_table{
//         .ngx-datatable{
//             .datatable-body{
//                 height: 35rem !important ;
//         }
//     }
       
// }
// }
@media screen and (min-width: 1010px){
    .evntList_page_table {
        .ngx-datatable{
                .datatable-body{
                    height: 0 !important;
                }
        }
    }
}
@media screen and (max-width: 980px){
    .evntList_page_table {
        &.event-list-table{
        .ngx-datatable{
                .datatable-body{
                    height: 20rem !important;
                }
            }
        }
    }
}
@media screen and (max-width: 540px){
    .evntList_page_table {
        .ngx-datatable{
                .datatable-footer{
                    .ml-custom-resp{
                        margin-left: 1rem !important;
                        
                    }
                }
        }
    }
}
@media screen and (min-width : 1740px){
    .donut_chart{
        .apexcharts-datalabels{
            display: none;
        }
        apx-chart{
            .apexcharts-canvas {
                foreignObject{
                    .apexcharts-legend{
                        top: 45px !important;
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
    }
}
// Side Bar Notification Css Offcanvas
.offcanvas {
    position: fixed !important;
    top: 4rem !important;
    z-index: var(--bs-offcanvas-zindex) !important;
    color: var(--bs-offcanvas-color);
    background-color: var(--bs-offcanvas-bg) !important;
    background-clip: padding-box !important;
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
    border: 1px solid $theme-color-sapphire-30 !important;
    max-width: 28rem !important;

    .offcanvas-body{
        max-height: calc(100vh - 205px ) !important;
    }
  }
  .offcanvas-backdrop.fade {
    opacity: 0 !important;
  }

  //ticket sales apex chart 
  .ticket_sales_tooltip{
    font-family: "Inter-400";
    color: $theme-color-Ivory ;
    font-size: $font-12;
  }

