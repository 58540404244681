@import "../../../assets/variables/variables.scss";

.custom_dropdown_container {
    position: relative;
    .custom-dropdown {
        position: relative;
        width: 100%;
        .selected-option {
          display: flex !important;
          align-items: center !important;
          padding: $font-10;
          background-color: $theme-color-ivory-80;
          color: $theme-color-white;
          height: 2.1875rem;
          // width: 7.75rem;
          min-width: 7.75rem;
          max-width: 10.5rem;
          font-size: $font-14;
          font-family: "Inter-600";
          letter-spacing: 1px;
          border: 1px solid $theme-color-ivory-80;
          cursor: pointer;
          border-radius: 0.438rem;
          justify-content: center;
          position: relative;
          z-index: 5;
          font-size: $font-14;
        }::after{
            display: none;
        }
        .dropdown-options {
          // top: 75%;
          // left: 0;
          inset: unset !important;
          top: 1.7rem !important;
          left: 0rem !important;
          min-width: 100%;
          transform: unset !important;
          // min-width: 7.75rem;
          background-color: $theme-color-white;
          border: 1px solid  $theme-color-ivory-80;
          // border-top: none;
          list-style-type: none;
          z-index: 4;
          color: $theme-color-ivory-80;
          font-size: $font-14;
          font-family: "Inter-600";
          max-height: 14rem;
          // padding: 0 5px;
          overflow: auto;
          border-bottom-left-radius: $font-10;
          border-bottom-right-radius: $font-10;
          width: 100%;
          overflow-x: hidden;
          // padding: $font-10 0px 0px 0px;
          .search-input{
            width: 100%;
            margin-top: 0.525rem ;//10px;
            border:0;
            border-bottom: 1px solid $theme-color-ivory-80 !important;
            outline: none;
            font-size: 0.875rem !important;
            font-family: "Inter-600";
            color: #6B6058;
            &::placeholder{
              color: #D6C5BD;
              font-size: 0.875rem !important;
              font-family: "Inter-600";
            }
            &:focus {
              border-color: $theme-color-ivory-80 !important ;
              
            }
          }
          .first_list{
            height: 100%;
            font-size: $font-14 !important;
            font-family: "Inter-600";
            color: $theme-color-ivory-80;
            text-align: center;
            line-height: $font-30;
            letter-spacing: 1px;
            display: flex;
            flex-direction: column;
            gap: 1px;
           
            li{
              cursor: pointer;
              &:nth-child(1){
                padding-top: 0.6rem;
              }
              &:hover{
                background-color: $theme-color-ivory-20;
              }
              &.active_option{
                background-color: $theme-color-ivory-20;
                // pointer-events: none;
              }
            }
         }
        }
      }

      &.selected{
        .selected-option {
          background-color: $theme-color-ivory-50 !important;
          border-color: $theme-color-ivory-50 !important;
          color: $theme-color-dark !important;
        }
        &.selected-option-hosted {
          color: $theme-color-sapphire-30 !important;}

        .dropdown-options {
          border-color: $theme-color-ivory-50 !important;
        }
       
      }
  }