$theme-color-sapphire-95    :#000F2D;
$theme-color-sapphire-50    :#5663EE;
$theme-color-sapphire-80    :#22317A;
$theme-color-sapphire-20    :#C4C9F1;
$theme-color-sapphire-10    :#E9EBF2;
$theme-color-sapphire-30    :#A4ABFE;
$theme-color-sapphire-50-20 :#5663EE33 ;
$theme-color-Ivory          :#332D28;
$theme-color-ivory-80       :#6B6058;
$theme-color-ivory-90       :#4E453D;
$theme-color-ivory-10       : #FFF8F1;
$theme-color-ivory-20       : #F0E6DF;
$theme-color-Ivory-40       : #D6C5BD;
$theme-color-ivory-50       : #C3B2A9;
$theme-color-ivory-60       : #A6978E;
$theme-color-black-salute   : #2F2F3B;



//accent
$theme-color-success        :#0DA14C;
$theme-color-accent         :#F35237;
$theme-color-Warning        :#E58A01;
$theme-color-error          :#C5260B;
$theme-color-warning-surface:#F9E8AF;
$theme-color-error-surface  :#FFDAD6;
$theme-color-success-surface:#D9E7CB;

//neutral
$theme-color-white          :#FFFFFF;
$theme-color-white-50       :#FFFFFF80;

$theme-color-grey           :#8D8D8D;
$theme-color-dark           :#00091A;

// custom font size variables start
$font-9 :0.563rem;
$font-10:0.625rem;
$font-11:0.688rem;
$font-12:0.75rem;
$font-13:0.813rem;
$font-14:0.875rem;
$font-15:0.938rem;
$font-16:1rem;
$font-17:1.063rem;
$font-18:1.125rem;
$font-19:1.188rem;
$font-20:1.25rem;
$font-21:1.313rem;
$font-22:1.375rem;
$font-23:1.438rem;
$font-24:1.5rem;
$font-25:1.563rem;
$font-26:1.625rem;
$font-27:1.688rem;
$font-28:1.75rem;
$font-29:1.813rem;
$font-30:1.875rem;
// custom font size variables end