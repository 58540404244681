html{
    @media (max-width: 1750px){
        font-size: 95%;
     };
    @media (max-width: 1650px){
        font-size: 90%;
     };
    @media (max-width: 1550px){
           font-size: 80%;
        };
    @media (max-width: 1420px){
        font-size: 75%;
        }
}