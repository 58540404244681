/**=====================
    25. Authentication CSS Start
==========================**/

@import '../../../assets/variables/variables.scss';

.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: $theme-color-white;
  background-position: center;
  padding: 30px 12px;

  .logo {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;

      &+.btn {
        margin-left: 5px;
      }

      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }

  .login-main { 
    width: 28.75rem !important;
    height:29.375rem !important;
    border-radius: 2.813rem !important;
    padding: 2.063rem !important; //33px
    border: 2px solid $theme-color-sapphire-50;
    // box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    .theme-form {
      h4 {
        margin-bottom: 5px;
      }

      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }

      .checkbox label::before {
        background-color: $card-body-color;
        border: 1px solid #dfdfdf;
      }

      .or {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 65%;
          height: 2px;
          background-color: #f3f3ff;
          top: 9px;
          z-index: 0;
          right: 0;
        }
      }

      p {
        color: $theme-color-dark ;
        letter-spacing: 0.3px;
      }
    
      .link {
        color:$theme-color-ivory-90;
        font-family: "Inter-500";
        letter-spacing: 0.3px !important;
      }
    }
  }
}

.needs-validation {
  .invalid-feedback {
    color: $dark-gray;
  }


  .invalid-tooltip {
    top: 10px;
    right: 10px;
  }
}

/**=====================
    25. Authentication CSS Ends
==========================**/